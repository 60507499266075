import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { useToast } from 'vue-toastification/composition'
import { onBeforeMount, provide, reactive } from '@vue/composition-api'

export default function useEdit() {
  const toast = useToast()
  const {
    router,
    route
  } = useRouter()

  const setModel = (id, name, key) => {
    const k = key || state.singleKey
    state.financePayment[`${k}_id`] = id
    state.financePayment[`${k}_name`] = name
  }

  const init = async () => {
    state.id = route.value.query.id
    state.readonly = route.value.params.readonly
    provide("_disabled",state.readonly)
    if (state.id) {
     await methods.edit()
    } else {
      state.financePayment.business_unit_type = '供应商'
      state.financePayment.currency = '人民币'
    }
  }

  onBeforeMount(async ()=>{
    await init()
  })

  const methods = {
    onUpdateAmount(payable_amount, paid_amount) {
      state.financePayment.payable_amount = payable_amount
      state.financePayment.paid_amount = paid_amount
    },
    async edit() {
      const res = await store.dispatch('financePayment/edit', { id: state.id })
      state.financePayment = Object.assign({},state.financePayment,res.data.data)
    },

    cancel() {
      router.go(-1)
    },

    async save() {
      const success = await state.observer.validate()
      if (!success) {
        toast.error('请正确填写必填字段')
        return
      }
      const editableData = state.itemList.editableData
      const editingData = Object.values(editableData).filter(item => !item || !item.readonly)
      if (editingData.length > 0) {
        toast.error('请保存明细列表未保存数据')
        return
      }
      const items = state.itemList.items
      const params = { ...state.financePayment }
      params.items = items
      store.dispatch('financePayment/save', params)
        .then(res => {
          if (res.data.code === 0) {
            toast.success('数据已保存!')
            router.push({ name: 'apps-financepayment-list' })
          } else {
            toast.error('保存失败')
          }
        })
    },
    showSingleModal(key) {
      state.singleKey = key
      state.singleModal.show()
    },
    setCompany(key, item) {
      switch (key) {
        case 'business_unit':
          setModel(item.company_id, item.company_name, 'payee')
          break
        case 'payment_org':
          setModel(item.company_id, item.company_name, 'settlement_org')
          setModel(item.company_id, item.company_name, 'purchase_org')
          break
        case 'settlement_org':
          setModel(item.company_id, item.company_name, 'purchase_org')
          break
      }
    },
    onSelectSingle(item) {
      switch (state.singleModalObj[state.singleKey].selectType) {
        case 'company':
          setModel(item.company_id, item.company_name)
          this.setCompany(state.singleKey, item)
          break
        case 'purchase_order':
          state.financePayment['order_id'] = item.order_id
          state.financePayment['order_no'] = item.order_no
          break
      }
      state.singleModal.hide()
    },

  }

  const state = reactive({
    id: 0,
    readonly:false,
    financePayment: reactive({
      f_payment_no: undefined,
      f_payment_type: undefined,
      business_date: undefined,
      business_unit_type: '',
      business_unit_name: '',
      expected_pay_date: undefined,
      bank_business_no: '',
      payee_type: undefined,
      payee_name: '',
      currency: '',
      payable_amount: undefined,
      paid_amount: undefined,
      f_payment_method: undefined,
      e_receipt_address: undefined,
      payment_org_name: undefined,
      settlement_org_name: undefined,
      purchase_org_name: undefined,
      purchase_dept_name: undefined,
      purchase_group_name: undefined,
      purchaser_name: undefined,
      fund_type: undefined,
      project: undefined,
      order_id:undefined,
      order_no:undefined,
      status:1,
      remark: undefined,
    }),

    //singleModal
    singleModal: undefined,
    companySelect: undefined,
    singleKey: 'payee',

    //
    itemList: undefined,

    page: {
      f_payment_no: {
        type: 'input',
        attrs: {
          id: 'f_payment_no',
          label: '付款单编号',
          readonly: true,
          placeholder: '保存后自动填充',
        },
      },
      f_payment_type: {
        type: 'select',
        attrs: {
          id: 'f_payment_type',
          label: '付款单类型',
          optionsType: 'f_payment_type',
          rules: 'required',
        },
      },
      business_date: {
        type: 'datePicker',
        attrs: {
          id: 'business_date',
          label: '业务日期',
          rules: 'required',
        },
      },
      business_unit_type: {
        type: 'input',
        attrs: {
          id: 'business_unit_type',
          label: '往来单位类型',
          readonly: true,
        },
      },
      business_unit_name: {
        type: 'input',
        attrs: {
          id: 'business_unit_name',
          label: '往来单位',
          rules: 'required',
          readonly: true,
          placeholder: '点击搜索往来单位',
        },
        on: {
          click: () => methods.showSingleModal('business_unit')
        }
      },
      expected_pay_date: {
        type: 'datePicker',
        attrs: {
          id: 'expected_pay_date',
          label: '期望付款日期',
        },
      },
      bank_business_no: {
        type: 'input',
        attrs: {
          id: 'bank_business_no',
          label: '银行流水号',
          readonly: true,
        },
      },
      payee_type: {
        type: 'select',
        attrs: {
          id: 'payee_type',
          label: '收款单位类型',
          readonly: true,
          optionsType: 'f_payee_type',
          rules: 'required',
        },
      },
      payee_name: {
        type: 'input',
        attrs: {
          id: 'payee_name',
          label: '收款单位',
          rules: 'required',
          readonly: true,
          placeholder: '点击搜索收款单位',
        },
        on: {
          click: () => methods.showSingleModal('payee')
        }
      },
      currency: {
        type: 'input',
        attrs: {
          id: 'currency',
          label: '币别',
          readonly: true,
        },
      },
      payable_amount: {
        type: 'input',
        attrs: {
          id: 'payable_amount',
          label: '应付金额',
          readonly: true,
        },
      },
      paid_amount: {
        type: 'input',
        attrs: {
          id: 'paid_amount',
          label: '实付金额',
          readonly: true,
        },
      },
      f_payment_method: {
        type: 'select',
        attrs: {
          id: 'f_payment_method',
          label: '支付方式',
          optionsType: 'f_payment_method',
          rules: 'required',
        },
      },
      e_receipt_address: {
        type: 'input',
        attrs: {
          id: 'e_receipt_address',
          label: '电子回单地址',
          readonly: true,
        },
      },
      payment_org_name: {
        type: 'input',
        attrs: {
          id: 'payment_org_name',
          label: '付款组织',
          rules: 'required',
          readonly: true,
          placeholder: '点击搜索付款组织',
        },
        on: {
          click: () => methods.showSingleModal('payment_org')
        }
      },
      settlement_org_name: {
        type: 'input',
        attrs: {
          id: 'settlement_org_name',
          label: '结算组织',
          rules: 'required',
          readonly: true,
          placeholder: '点击搜索结算组织',
        },
        on: {
          click: () => methods.showSingleModal('settlement_org')
        }
      },
      purchase_org_name: {
        type: 'input',
        attrs: {
          id: 'purchase_org_name',
          label: '采购组织',
          rules: 'required',
          readonly: true,
          placeholder: '点击搜索采购组织',
        },
        on: {
          click: () => methods.showSingleModal('purchase_org')
        }
      },
      purchase_dept_name: {
        type: 'input',
        attrs: {
          id: 'purchase_dept_name',
          label: '采购部门',
          readonly: true,
        }
      },
      purchase_group_name: {
        type: 'input',
        attrs: {
          id: 'purchase_group_name',
          label: '采购组织',
          readonly: true,
        }
      },
      purchaser_name: {
        type: 'input',
        attrs: {
          id: 'purchaser_name',
          label: '采购人员',
          readonly: true,
        }
      },
      fund_type: {
        type: 'select',
        attrs: {
          id: 'fund_type',
          label: '款项类型',
          optionsType: 'f_fund_type',
          rules: 'required',
        },
      },
      project: {
        type: 'select',
        attrs: {
          id: 'project',
          label: '项目',
          optionsType: 'f_project',
        },
      },
      /*order_no:{
        type: 'input',
        attrs: {
          id: 'order_no',
          label: '采购订单',
          rules: 'required',
          readonly: true,
          placeholder: '点击搜索采购订单',
        },
        on: {
          click: () => methods.showSingleModal('purchase_order')
        }
      }*/
    },
    singleModalObj: {
      business_unit: {
        modalName: '往来单位',
        selectType: 'company',
        companyType: 'Supplier'
      },
      payee: {
        modalName: '收款单位',
        selectType: 'company',
        companyType: 'Supplier'
      },
      payment_org: {
        modalName: '付款组织',
        selectType: 'company',
        companyType: 'Group',
      },
      settlement_org: {
        modalName: '结算组织',
        selectType: 'company',
        companyType: 'Group'
      },
      purchase_org: {
        modalName: '采购组织',
        selectType: 'company',
        companyType: 'Group'
      },
      purchase_order: {
        modalName: '采购订单',
        selectType: 'purchase_order',
        size:"xl"
      },
    },

    observer: undefined,
    attachmentsWh: '',

  })

  return {
    state,
    methods,
  }
}
