<template>
  <b-card
  >
    <!-- form -->
    <b-form id="financePaymentForm" class="edit-form mt-2">
      <ValidationObserver ref="observer">
      <b-row>
        <b-col md="12">
          <div class="inner-card-title">基本信息</div>
        </b-col>
        <b-col v-for="(value,name,index) in page" :key="index" md="4">
          <xy-form  :prop-data="value" v-model="financePayment[name]"></xy-form>
        </b-col>
        <b-col md="12">
          <xy-textarea  label-cols="1" id="remark" label="备注" :val.sync="financePayment.remark" rules="required"/>
        </b-col>
      </b-row>
      </ValidationObserver>
      <b-row>
        <b-col md="12">
          <div class="inner-card-title">明细列表</div>
        </b-col>
        <b-col>
          <finance-payment-item-list ref="itemList" :f_payment_id="id"
            @updateAmount="onUpdateAmount"
          >
          </finance-payment-item-list>
        </b-col>
      </b-row>
      <b-row >
        <b-col
            cols="12"
            class="mt-50"
        >
          <xy-button
              variant="primary"
              class="mr-1"
              @click="save"
          >
            保存
          </xy-button>
          <b-button
              variant="outline-secondary"
              @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
    <b-modal
        id="modal-single"
        centered
        :size="singleModalObj[singleKey].size || 'lg'"
        hide-footer
        :title="`请选择${singleModalObj[singleKey].modalName}`"
        ref="singleModal"
    >
      <company-select
          @table="onSelectSingle"
          ref="companySelect" :companyType="singleModalObj[singleKey].companyType"
          v-if="singleModalObj[singleKey].selectType === 'company'"
      >
      </company-select>

      <purchase-order-select @onRowDblClicked="onSelectSingle" ref="orderSelect"  v-if="singleModalObj[singleKey].selectType === 'purchase_order'" >

      </purchase-order-select>
    </b-modal>
  </b-card>
</template>

<script>
import { onUnmounted, toRefs } from '@vue/composition-api'
import store from '@/store'
import financePaymentStore from './financepaymentStore'
import { getCode, getCodeColor, getCodeLabel, getCodeOptions, toDate, toTime } from '@core/utils/filter'
import CompanySelect from '@/views/apps/company/CompanySelect'
import useEdit from '@/views/apps/financepayment/useEdit'
import XyForm from '@/views/components/xy/XyForm'
import XyTextarea from '@/views/components/xy/XyTextarea'
import FinancePaymentItemList from '@/views/apps/financepaymentitem/FinancePaymentItemList'
import XyButton from '@/views/components/xy/XyButton'
import PurchaseOrderSelect from '@/views/apps/purchaseorder/select/PurchaseOrderSelect'

export default {
  components: {
    PurchaseOrderSelect,
    XyButton,
    FinancePaymentItemList,
    XyTextarea,
    XyForm,
    CompanySelect
  },
  setup() {
    // Register module
    if (!store.hasModule('financePayment')) store.registerModule('financePayment', financePaymentStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('financePayment')) store.unregisterModule('financePayment')
    })

    const {
      methods: useMethods,
      state: useState
    } = useEdit()


    return {
      ...toRefs(useState),
      ...useMethods,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toTime,
      toDate,
    }
  },
}
</script>

<style lang="scss">
</style>
